.work-experience {
    margin-top: 32px;
    padding: 24px;
    border-radius: 8px;
    background-color: rgb(15, 23, 42);
}

.work-title > h2 {
    color: rgb(209, 213, 219);
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0;
}

.work-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 0px;
}

.work-experience-wrapper {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 5px;
}

.work {
    padding-bottom: 16px;
    padding-left: 16px;
    border-left: solid 2px;
    border-color: rgb(37, 99, 235);
}

.work:first-of-type {
    margin-bottom: 0px;
}

.work:not(:first-of-type) {
    margin-top: 24px;
}

.work > h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: rgb(34, 211, 238);
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
}

.company {
    color: rgb(209, 213, 219);
    font-size: 15px;
}

.work > .duration {
    color: rgb(156, 163, 175);
    font-size: 14px;
}

.description {
    margin-top: 16px;
    margin-bottom: 0px;
    color: rgb(255, 255, 255);
    font-size: 16px;
}

.work-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}