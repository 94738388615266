.body {
  background-color: rgb(23, 37, 84);
  height: 100%;
  margin: 0;
  min-height: 100%;
  font-size: 14px;
  line-height: 20px;
  color: #333;
}

* {
  box-sizing: border-box;
  font-family: "Inter", Helvetica, sans-serif !important;
}

.body-sections {
  padding: 32px 16px;
  padding-bottom: 0px;
  display: block;
  margin: 0 auto;
  max-width: 940px;
}

/*
h2 {
  font-size: 24px !important;
}


h3 {
  font-size: 18px !important;
  font-weight: 600;
}

.company {
  font-size: 16px !important;
  font-weight: 600;
}

.date {
  font-size: 14px !important;
}

.text {
  font-size: 16px !important;
}

.button {
  font-size: 14px;
}

.tag {
  font-size: 12px;
}

heading icon: 20x20, 24x24
button icon: 15x15, 16x16
  
Test*/