.projects {
    margin-top: 32px;
    padding: 24px;
    border-radius: 8px;
    background-color: rgb(15, 23, 42);
}

.projects-title > h2 {
    color: rgb(209, 213, 219);
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0;
}

.projects-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 0px;
}

.projects-wrapper {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 5px;
}

.project {
    padding-bottom: 16px;
    padding-left: 16px;
    border-left: solid 2px;
    border-color: rgb(37, 99, 235);
}

.project:first-of-type {
    margin-bottom: 0px;
}

.project:not(:first-of-type) {
    margin-top: 24px;
}

.project > h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: rgb(34, 211, 238);
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
}

.description {
    margin-top: 16px;
    margin-bottom: 0px;
    color: rgb(209, 213, 219);
    font-size: 16px;
}

.used-technologies {
    margin-top: 8px;
}

.used-technologies-wrapper {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.technology {
    display: flex;
    padding: 4px 8px;
    height: 28px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: rgba(37, 99, 235, 0.1);
    color: rgba(34, 211, 238, 0.8);
    text-align: center;
    font-size: 12px;
}

.project-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.project-button-icon {
    width: 16px;
    height: 16px;
}

.demo .project-button-icon {
    width: 13px;
    height: 13px;
}



.project-buttons {
    margin-top: 16px;
    display: flex;
    gap: 16px;
}

.demo, .github {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    border: 1px solid rgba(64, 224, 208, 0.2);
    height: 36px;
    border-radius: 6px;
    padding: 0 12px;
    background-color: rgba(37, 100, 235, 0.257);
    color: rgb(34, 211, 238);
    transition: color 0.2s, background-color 0.2s;
    text-decoration: none;
}

.demo:hover, .github:hover {
    background-color: rgb(34, 211, 238, 0.1);
  }