.aboutme {
    margin-top: 0px;
    padding: 24px;
    border-radius: 8px;
    background-color: rgb(15, 23, 42);
}

.aboutme-wrapper {
    spadding-bottom: 16px;
    spadding-left: 16px;
    /*grid-template-rows: auto;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 16px;*/
}

.aboutme-info-wrapper {
    display: flex;
    justisfy-self: center;
    align-items: center;
}

.aboutme-info {
    margin-left: 16px;
}

.aboutme-profile-image {

}

.profile-image {
    border-radius: 9999px; /* rounded-full */
    border-width: 4px; /* border-4 */
    border-color: #333333; /* border-cyan-400 */
    background-color: #22d3ee;
    border-style: solid;
}

.aboutme-profile {
    paddsing-bottom: 16px;
    paddsing-left: 16px;
    display: flex;
    justsify-content: center;
    justsify-content: space-around;
    borsder-left: solid 2px;
    bosrder-color: rgb(37, 99, 235);
}

.info-title-name-wrapper > .name {
    margin-top: 20px;
    margin-bottom: 8px;
    color: rgb(255, 255, 255);
    font-size: 30px;
}

.info-title-name-wrapper > .title {
    margin-top: 0px;
    margin-bottom: 16px;
    color: rgb(34, 211, 238);
    font-size: 20px;
    font-weight: 400;
}

.social-networks {
    display: flex;
    align-items: center;
}

.info-other > .email, .linkedin-profile, .github-profile {
    margin-top: 0;
    color: rgb(209, 213, 216);
}

.email {
    font-size: 16px;
}

.adress {
    color: rgb(156, 163, 175);
    font-size: 14px;
}

.linkedin-profile {
    margin-left: 10px;
}

.email {
    text-decoration: underline;
    transition: color 0.2s, background-color 0.2s;
}

.email:hover {
    cursor: pointer;
    text-decoration: underline;
    color: rgb(34, 211, 238);
}

.copy-icon {
    width: 15px;
    height: 15px;
    margin-left: 5px;
}

.social-networks {
    margin-top: 7px;
}

.linkedin-icon, .github-icon {
    width: 14px;
    height: 14px;
    transition: all 0.2s;
}

.linkedin-icon:hover, .github-icon:hover {
    border: 1px solid rgb(34, 211, 238);
    padding: 1px;
}

.github-icon:hover {
    border-radius: 100%;
}

.copy-message {
    position: absolute;
    margin-left: 50px;
    transform: translateX(-50%);
    background-color: #000;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    opacity: 0.9;
    white-space: nowrap;
    z-index: 10;
    pointer-events: none;
    animation: fade-out 2s forwards;
  }

  .email {
    position: relative;
  }

  .aboutme-summary-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.aboutme-summary-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 20px;
}

.aboutme-summary-title {
    color: rgb(34, 211, 238);
    margin: 0px;
    font-size: 24px;
}

.aboutme-summary-text {
    color: rgb(209, 213, 216);
    font-size: 14px;
    text-align: left;
    width: 100%;
}

@media only screen and (max-width: 680px) {
    .aboutme-profile {
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    .aboutme-summary-wrapper {
        justify-content: flex-start;
    }
    
    .aboutme-summary-text {
        text-align: center;
    }

    .aboutme-profile {
        padsding-bottom: 16px;
        padsding-left: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-directison: column;
        borsder-left: solid 2px;
        bosrder-color: rgb(37, 99, 235);
    }

    .aboutme-profile {
        text-align: center;
        justify-content: center;
    }

    .social-networks {
        justify-content: center;
    }

    .aboutme-wrapper {
        padding: 0;
    }
}