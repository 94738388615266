.header {
    position: sticky;
    top: 0;
    backdrop-filter: blur(4px);
    z-index: 1;
}

.header-body {
    z-index: 0;
    display: flex;
    width: 100%;
    height: 65px;
    max-width: 1140px;
    margin-right: auto;
    margin-left: auto;
    padding: 20px;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(30, 64, 175, 0.3);
    border-radius: 8px;
    background-color: rgba(20, 27, 36, 0.5);
}

.header-container {
    width: 100%;
    max-width: 1260px;
    min-height: 30px;
    margin-right: auto;
    margin-left: auto;
}

.header-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button-cv-download {
    display: flex;
    height: 35px;
    padding-right: 15px;
    padding-left: 15px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border: 1px solid rgb(34, 211, 238, 0.2);
    border-radius: 8px;
    background-color: rgba(37, 99, 235, 0.615);
    font-family: Exo, sans-serif;
    color: rgb(34, 211, 238);
    font-size: 14px;
    text-decoration: none;
    transition: all 0.2s;
}

.button-cv-download:hover {
    background-color: rgb(34, 211, 238, 0.1)
}

.brend-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.brand-name {
    margin-left: 10px;
    color: rgb(34, 211, 238);
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
}

.brand-icon {
    color: rgb(34, 211, 238);
}

.download-icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.brend-wrapper .brand-name {
    color: rgb(34, 211, 238);
    text-decoration: none;
    position: relative;
  }
  
  .brend-wrapper .brand-name::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 0;
    height: 2px;
    background-color: rgb(37, 99, 235);
    transition: width 0.3s ease;
  }
  
  .brend-wrapper .brand-name:hover::after {
    width: 100%;
  }