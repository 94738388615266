.footer-wrapper {
    border: solid 1px rgba(30, 64, 175, 0.3);
    position: static;
    inset: auto 0% 0%;
    display: flex;
    height: 40px;
    max-height: 200px;
    margin-top: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: rgba(20, 27, 36, 0.5);
    height: auto;
}

.footer-text {
    color: rgb(156, 163, 175);
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    padding: 16px;
}