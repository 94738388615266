.skills {
    margin-top: 32px;
    padding: 24px;
    border-radius: 8px;
    background-color: rgb(15, 23, 42);
}

.skills-title > h2 {
    color: rgb(209, 213, 219);
    font-size: 24px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.skills-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 0px;
}

.skill-technologies {
    padding-bottom: 16px;
    padding-top: 20px;
}

.skill-technologies-wrapper {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.skill-technology {
    display: flex;
    padding: 4px 12px;
    height: 28px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: rgba(37, 99, 235, 0.1);
    color: rgb(34, 211, 238);
    text-align: center;
    font-size: 14px;
}

.skills-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}